import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Contacto from "../components/contacto"
import imgAquadex from '../images/products/aquadex.jpg'
import imgCoubix from '../images/products/coubix.jpg'
import imgDufnerCardiaca from '../images/products/dufner-cardiaca.jpg'
import imgDufnerPediatrica from '../images/products/dufner-pediatrica.jpg'
import imgDufnerPlastica from '../images/products/dufner-plastica.jpg'
import imgDufnerTorascoscopica from '../images/products/dufner-torascoscopica.jpg'
import imgGrandfix from '../images/products/grandfix.jpg'
import imgMaskerone from '../images/products/maskerone.jpg'
import imgNitiRib from '../images/products/niti-rib.jpg'
import imgSeparadorTsi from '../images/products/separador-tsi.jpg'
import imgSnake from '../images/products/snake.jpg'
import imgTissuePatch from '../images/products/tissue-patch.jpg'
import imgTrueFlow from '../images/products/true-flow.jpg'
import imgTsiCardiaca from '../images/products/tsi-cardiaca.jpg'
import imgReparacion from '../images/icons/reparacion.svg'
import imgReciclaje from '../images/icons/reciclaje.svg'


const Productos = () => (
  <Layout className="page-productos">
    <SEO title="Productos" />

	<div className="main-bloque">
		<div className="container">

		    <h1 className="main-title">Productos</h1>

			<section className="bloque bloque-toracica">
					<h2 className="title">Cirugía torácica</h2>

					<div className="row">
						<article className="producto col-sm-6 col-md-3">
							<figure>
								<img className="img-productos" src={imgCoubix} alt="Codubix" />
							</figure>
							<h4>Codubix</h4>
							<p>Malla para defectos de pared torácica. </p>
						</article>
						<article className="producto col-sm-6 col-md-3">
							<figure>
								<img className="img-productos" src={imgNitiRib} alt="NitiRib" />
							</figure>			
							<h4>NitiRib</h4>
							<p>Grapas de Nitinol para osteosíntesis costal.</p>
						</article>
						<article className="producto col-sm-6 col-md-3">
							<figure>
								<img className="img-productos" src={imgGrandfix} alt="GrandFix" />
							</figure>
							<h4>GrandFix</h4>
							<p>Pins para ostesíntesis esternal y costal. </p>
						</article>
						<article className="producto col-sm-6 col-md-3">
							<figure>
								<img className="img-productos" src={imgTissuePatch} alt="TissuePatch" />
							</figure>			
							<h4>TissuePatch</h4>
							<p>Parche sellante y hemostático.</p>
						</article>				
						<article className="producto col-sm-6 col-md-3">
							<figure>
								<img className="img-productos" src={imgSeparadorTsi} alt="Separador TSI" />
							</figure>
							<h4>Separador TSI</h4>
							<p>Separador ultravisón y fuente de luz phantom. </p>
						</article>
						<article className="producto col-sm-6 col-md-3">
							<figure>
								<img className="img-productos" src={imgDufnerTorascoscopica} alt="Dufner" />
							</figure>			
							<h4>Dufner</h4>
							<p>Instrumental cirugía Toracoscópica y VATS.</p>
						</article>				
					</div>			
				</section>
		</div>
	</div>

	<section className="bloque bloque-cardiaca">
		<div className="container">
			<h2 className="title">Cirugía cardíaca</h2>

			<div className="row">
				<article className="producto col-md-6">
					<figure>
						<img className="img-productos" src={imgTrueFlow} alt="True flow RDB" />
					</figure>
					<h4>True flow RDB</h4>
					<p>Cánula de perfusión cerebral anterógrada larga y maleable. </p>
				</article>
				<article className="producto col-sm-6 col-md-3">
					<figure>
						<img className="img-productos" src={imgSnake} alt="Snake" />
					</figure>			
					<h4>Snake</h4>
					<p>Organizador de suturas maleable. Disponible en 3 tamaños.</p>
				</article>
				<article className="producto col-sm-6 col-md-3">
					<figure>
						<img className="img-productos" src={imgTsiCardiaca} alt="TSI" />
					</figure>
					<h4>TSI</h4>
					<p>Sistema elevador para IMA.</p>
				</article>
				<article className="producto col-sm-6 col-md-3">
					<figure>
						<img className="img-productos" src={imgGrandfix} alt="GrandFix" />
					</figure>			
					<h4>GrandFix</h4>
					<p>Pins para osteosíntesis esternal y costal.</p>
				</article>				
				<article className="producto col-sm-6 col-md-3">
					<figure>
						<img className="img-productos" src={imgDufnerCardiaca} alt="Dufner" />
					</figure>
					<h4>Dufner</h4>
					<p>Instrumental cirugía cardíaca. </p>
				</article>
				<article className="producto col-sm-6 col-md-3">
					<figure>
						<img className="img-productos" src={imgSeparadorTsi} alt="Separador TSI" />
					</figure>			
					<h4>Separador TSI</h4>
					<p>Separador ultravisón y fuente de luz phantom.</p>
				</article>				
			</div>			
		</div>
	</section>

	<section className="bloque bloque-cardiologia">
		<div className="container">
			<h2 className="title">Cardiología</h2>

			<div className="row">
				<article className="producto col-sm-6 col-md-3">
					<figure>
						<img className="img-productos" src={imgAquadex} alt="Aquadex FlexFlow" />
					</figure>
					<h4>Aquadex FlexFlow</h4>
					<p>Tratamiento de ultrafiltración en pacientes con sobrecarga de fluidos. </p>
				</article>			
			</div>			
		</div>
	</section>	

	<section className="bloque bloque-otros">
		<div className="container">
			<h2 className="title">Otros productos</h2>

			<div className="row">
				<article className="producto col-sm-6 col-md-3">
					<figure>
						<img className="img-productos" src={imgMaskerone} alt="MaskerOne" />
					</figure>
					<h4>MaskerOne</h4>
					<p>Máscara protectora facial para pacientes en posición “prono”.</p>
				</article>
				<article className="producto col-sm-6 col-md-3">
					<figure>
						<img className="img-productos" src={imgDufnerPlastica} alt="Dufner" />
					</figure>			
					<h4>Dufner</h4>
					<p>Instrumental cirugía plástica.</p>
				</article>
				<article className="producto col-sm-6 col-md-3">
					<figure>
						<img className="img-productos" src={imgDufnerPediatrica} alt="Dufner" />
					</figure>
					<h4>Dufner</h4>
					<p>Instrumental cirugía pediátrica. </p>
				</article>
			</div>			
		</div>
	</section>	

	<section className="bloque bloque-servicios">
		<div className="container">
			<h2 className="title">Nuevos servicios</h2>

			<div className="row">
				<div className="col-md-6 col-servicio">
					<article className="servicio">
						<figure>
							<img className="img-servicios" src={imgReparacion} alt="" />
						</figure>
						<h4>Reparación de instrumental</h4>
						<p>Reparamos todo aquel material que todavía puede tener otra oportunidad. </p>
					</article>
				</div>
				<div className="col-md-6 col-servicio">
					<article className="servicio">
						<figure>
							<img className="img-servicios" src={imgReciclaje} alt="" />
						</figure>			
						<h4>Reciclaje de instrumental</h4>
						<p>Nos encargamos de la recogida del material y de su correcta manipulación para ser reciclado</p>
					</article>
				</div>
			</div>
		</div>
	</section>


	<Contacto />
  </Layout>
)

export default Productos
